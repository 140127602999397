.tokenomics-bg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f3f3f3;
  position: relative;
  padding-bottom: 7rem;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: 1;
    background-image: url("../../media/bg/bg-grad3.png");
    opacity: 1;
  }
  .tokenomics-title {
    .main-tokenomics-title {
      letter-spacing: 1px;
      // text-transform: uppercase;
      font-size: 2.5rem;
      font-weight: 700;
      @media screen and (max-width: 500px) {
        font-size: 2rem;
      }
    }
    .main-tokenomics-description {
      margin-top: 0.5rem;
    }
    // position: relative;
    color: black;
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 2rem;
    margin-top: 3rem;
  }
  .tokenomics-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .tokenomics-img-container {
      margin-top: 5rem;
      position: relative;
      left: 20px;
      img.tok-img {
        position: relative;
        z-index: 2;
        height: 670px;
        width: 670px;
        @media screen and (max-width: 768px) {
          height: 560px;
          width: 560px;
        }
        @media screen and (max-width: 500px) {
          height: 460px;
          width: 460px;
        }
      }
      .tokenomics-num {
        .num {
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translateX(-50%);
          border: 5px solid #000;
          background-color: #fff;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          font-size: 1.4rem;
          @media screen and (max-width: 500px) {
            height: 40px;
            width: 40px;
          }
        }
        .text {
          font-size: 1.5rem;
          margin-bottom: 1rem;
          font-weight: 700;
          @media screen and (max-width: 768px) {
            font-size: 1.3rem;
          }
          @media screen and (max-width: 500px) {
            font-size: 1rem;
          }
        }
      }
      .tokenomics-num.num2 {
        position: absolute;
        top: -50px;
        left: 240px;
        z-index: 3;
        font-weight: 700;
        @media screen and (max-width: 768px) {
          top: -52px;
          left: 200px;
        }
        @media screen and (max-width: 500px) {
          top: -43px;
          left: 162px;
        }
      }
      .tokenomics-num.num1 {
        position: absolute;
        bottom: 39px;
        left: 321px;
        z-index: 3;
        font-weight: 700;
        .text {
          margin-top: 1rem;
        }
        @media screen and (max-width: 768px) {
          bottom: 17px;
          left: 260px;
        }
      }
      .tokenomics-num.num3 {
        position: absolute;
        top: 15px;
        left: 127px;
        z-index: 3;
        font-weight: 700;
        .text {
          transform: translateX(-80%);
        }
        @media screen and (max-width: 768px) {
          top: -4.9px;
          left: 101px;
        }
        @media screen and (max-width: 500px) {
          top: 1.1px;
          left: 88px;
        }
      }
      .tokenomics-img1 {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 262px;
        left: 186px;
        // transform: translateX(-50%);
        border: 5px solid #000;
        height: 250px;
        width: 250px;
        border-radius: 50%;
        background-color: #f902fc;
        overflow: hidden;
        z-index: 2;
        @media screen and (max-width: 768px) {
          bottom: 222px;
          left: 159px;
          height: 200px;
          width: 200px;
        }
        @media screen and (max-width: 500px) {
          bottom: 190px;
          left: 139px;
          height: 150px;
          width: 150px;
        }
        img {
          height: 220px;
          position: absolute;
          left: -58px;
          top: 30px;
          @media screen and (max-width: 768px) {
            height: 160px;
            left: -28px;
            top: 42px;
          }
          @media screen and (max-width: 500px) {
            height: 120px;
            left: -25px;
            top: 32px;
          }
        }
      }
    }
    .tok-info {
      display: flex;
      gap: 1rem;
      font-weight: 700;
      // margin-top: 2rem;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 0.5rem;
      }
      .tok-elem {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          height: 200px;
          transform: rotate();
          position: relative;
          bottom: -10px;
          @media screen and (max-width: 1050px) {
            height: 150px;
          }
        }
        .tok-box {
          width: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          // height: 20px;
          background-color: #fff;
          border: 2px solid #1a1a1a;
          border-bottom-width: 5px;
          color: #000;
          border-radius: 20px;
          max-width: 100%;
          margin-left: 0;
          margin-right: 0;
          padding: 10px;

          box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
            inset 5px 5px rgba(255, 255, 255, 0.5);
          text-align: center;
          @media screen and (max-width: 1050px) {
            width: 200px;
          }
          @media screen and (max-width: 768px) {
            width: 250px;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // z-index: 1;
            background-image: url("../../media/bg/bg-grad1.png");
            opacity: 1;
          }

          .box-title {
            font-size: 1.5rem;
            color: #000;
            // margin-bottom: 1rem;
            span {
              text-decoration-line: line-through;
              text-decoration-thickness: 3px;
            }
            @media screen and (max-width: 1050px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 768px) {
              font-size: 1.5rem;
            }
          }
        }
      }
      .tok-elem.off {
      }
      .tok-box.box1 {
        transform: rotate(-3deg);
        padding: 10px;
        img {
          position: relative;
          top: 15px;
        }

        .bottom {
          text-align: left;
          margin-left: 1rem;
        }
      }
      .tok-box.box2 {
        position: relative;
        // top: 25px;
        // transform: rotate(12deg);
        // padding: 0;
      }
      .tok-box.box3 {
        transform: rotate(-2deg);
        // padding: 0;
      }
    }
  }
  // .tokenomics-img2 {
  //   position: absolute;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   top: 228px;
  //   right: 55px;
  //   img {
  //     height: 315px;
  //     border-radius: 5px;
  //   }
  // }
  .tokenomics-img {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
      inset 5px 5px rgba(255, 255, 255, 0.239);
    border: 3px solid #000;
    border-radius: 8px;
    height: 390px;
    border-bottom: 5px solid #000;
    border-right: 5px solid #000;
    overflow: hidden;
    video {
      height: 400px;
      border-radius: 5px;
    }
  }
  .tokenomics-img.img3 {
    top: -110px;
    left: 155px;
    transform: rotate(3deg);
  }
  .tokenomics-img.img2 {
    bottom: 400px;
    right: 155px;
    transform: rotate(-3deg);
  }

  // .tokenomics-img2 {
  //   position: absolute;
  //   top: 230px;
  //   left: 150px;
  //   transform: rotate(3deg);
  //   box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
  //     inset 5px 5px rgba(255, 255, 255, 0.239);
  //   border-radius: 10px;
  //   border: 3px solid #000;
  //   border-bottom: 5px solid #000;
  //   border-right: 5px solid #000;
  //   height: 400px;
  //   video {
  //     height: 400px;
  //     border-radius: 5px;
  //   }
  // }
}

@keyframes spin {
  0% {
    width: var(--coin-size);
    box-shadow: 0 0 0 var(--side-dark);
    animation-timing-function: ease-in;
  }

  49.999% {
    width: 0.1rem;
    box-shadow: 0.05rem 0 0 var(--side), 0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side), 0.2rem 0 0 var(--side), 0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side), 0.35rem 0 0 var(--side), 0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side), 0.5rem 0 0 var(--side), 0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side), 0.65rem 0 0 var(--side), 0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
    transform: translateX(-0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: linear;
  }

  50.001% {
    width: 0.1rem;
    box-shadow: -0.05rem 0 0 var(--side), -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side), -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side), -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side), -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side), -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side), -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side), -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
    transform: translateX(0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: ease-out;
  }

  100% {
    width: var(--coin-size);
    box-shadow: 0 0 0 var(--side-dark);
  }
}
