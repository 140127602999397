// .front-page {
//   // height: 750px;
//   overflow: hidden;
//   background-color: #f3f3f3;
//   border-top: 1.8px solid #000;
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     // z-index: 1;
//     background-image: url("./media/bg/bg-grad3.png");
//     opacity: 1;
//   }
// }

.top-btn {
  position: fixed;
  color: white;
  bottom: 15px;
  left: 20px;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    @media screen and (max-width: 768px) {
      transform: scale(1);
    }
  }
}

.top-btn-style {
  border: 2px solid white;
  height: 40px;
  width: 40px;
  padding: 7px;
  border-radius: 50%;
  @media screen and (max-width: 768px) {
    height: 25px;
    width: 25px;
  }
}
