.top-page {
  @media screen and (max-width: 950px) {
    display: none;
  }
}
.meme-page-bg-container {
  background-color: #f902fc;
  height: 100%; //
  min-height: 100vh;
  border-top: 3px solid #000;
  // overflow: hidden;

  padding: 0 0 2rem 0;
  // @media screen and (max-width: 950px) {
  //   height: 100vh;
  // }
  .meme-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    width: 60%;
    margin: auto;
    @media screen and (max-width: 890px) {
      width: 90%;
      padding: 2rem 0;
    }
    @media screen and (max-width: 400px) {
      width: 100%;
      // padding: 2rem 0;
    }
    .nav-list {
      // position: absolute;
      ul {
        display: flex;
        gap: 1.5rem;
        width: 100%;
        @media screen and (max-width: 890px) {
          width: 90%;
          padding: 0;
          width: 100%;
        }
        li {
          list-style: none;
          transition: 0.2s ease;
          &:nth-child(odd) {
            transform: rotate(1.5deg);
          }
          &:nth-child(even) {
            transform: rotate(-1.5deg);
          }

          &:hover {
            transform: scale(1.1);
            font-weight: 700;
          }
        }
        a {
          @media screen and (max-width: 890px) {
            padding: 0.6rem 1.5rem;
          }
          @media screen and (max-width: 550px) {
            padding: 0.5rem 1rem;
          }
        }
        @media screen and (max-width: 1100px) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 965px) {
          font-size: 0.6rem;
        }
      }
    }
  }
  .pictures-tab {
    .pictures-container {
      position: relative;
      max-width: 2460px;
      margin: auto;
      .meme-grid-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0 1rem;
        width: 70%;
        margin: auto;
        height: 70vh;
        margin: auto;
        background: #fff;
        border-style: solid;
        border-width: 15px;
        border-top-color: lighten(#000, 20%);
        border-right-color: lighten(#000, 0%);
        border-bottom-color: lighten(#000, 20%);
        border-left-color: lighten(#000, 0%);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);

        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          // z-index: 1;
          background-image: url("../../media/bg/bg-grad3.png");
          opacity: 1;
        }

        .image-container {
          /* Just in case there are inline attributes */
          width: 100%;
          // padding: 5px;
          height: auto;
        }
        .meme-card {
          margin: 3rem;
          height: 90%;
          width: 100%;
          overflow: scroll;
          scrollbar-width: none;
          border: 5px solid #1a1a1a;
          position: relative;
          &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }
          @media screen and (max-width: 768px) {
            margin: 1rem;
          }
        }
        .meme-img {
          background-color: #1a1a1a;
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            margin: auto;
            background-color: #000;
            opacity: 0.9;
            z-index: 3;
          }

          //   padding: 1rem;
          overflow: hidden;
          /* Prevent vertical gaps */
          line-height: 0;
          //   position: relative;
          -webkit-column-count: 5;
          -moz-column-count: 5;
          column-count: 5;
          column-gap: 0px;
          row-gap: 0px;
          width: 100%;
          filter: grayscale(0);
          cursor: pointer;
        }
        .meme-grid-container.black {
          filter: grayscale(100%);
          background-color: #000; /* Set background to black */
          transition: background-color 0.3s ease;
        }
        @media (max-width: 1200px) {
          .meme-img {
            -moz-column-count: 4;
            -webkit-column-count: 4;
            column-count: 4;
          }
        }
        @media (max-width: 1000px) {
          .meme-img {
            -moz-column-count: 3;
            -webkit-column-count: 3;
            column-count: 3;
          }
        }
        @media (max-width: 800px) {
          .meme-img {
            -moz-column-count: 2;
            -webkit-column-count: 2;
            column-count: 2;
          }
        }
        @media (max-width: 400px) {
          .meme-img {
            -moz-column-count: 1;
            -webkit-column-count: 1;
            column-count: 1;
          }
        }
      }
      .background {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .close {
          position: absolute;
          z-index: 4;
          font-size: 5rem;
          top: 40px;
          right: 5rem;
          color: white;
          transition: 0.2s ease;
          @media screen and (max-width: 768px) {
            top: 0px;
            right: 35px;
            height: 50px;
            width: 50px;
          }
          &:hover {
            cursor: pointer;
            // color: #f902fc;
            font-size: 5.5rem;
            top: 38px;
            right: 4.8rem;
          }
        }
        .arrow {
          position: absolute;
          z-index: 4;
          font-size: 5rem;
          color: #fff;
          opacity: 1;
          top: 50%;
          transform: translateY(-50%);
          transition: 0.1s ease;
          &:hover {
            cursor: pointer;
            // color: #f902fc;
            font-size: 5.2rem;
          }
          &.right {
            right: 5rem;
            &:hover {
              right: 4.8rem;
            }
          }
          &.left {
            left: 5rem;
            &:hover {
              right: 4.8rem;
            }
          }
        }
        .enlarged-img {
          position: absolute;
          -khtml-user-select: none;
          -o-user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          user-select: none;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          /* Adjust scale for desired enlargement */
          z-index: 1000;
          opacity: 1;
          height: 50%;
          width: auto;
          border-style: solid;
          border-width: 15px;
          border-top-color: lighten(#e2e2e2, 50%);
          border-right-color: lighten(#e2e2e2, 0%);
          border-bottom-color: lighten(#e2e2e2, 50%);
          border-left-color: lighten(#e2e2e2, 0%);
          @media screen and (max-width: 768px) {
            height: 35%;
          }
        }
      }
      .pooti-pic-link {
        position: relative;
        // top: 650px;
        // left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // @media screen and (max-width: 950px) {
        //   top: 590px;
        // }
        // @media screen and (max-width: 950px) {
        //   top: 550px;
        // }
        // @media screen and (max-width: 450px) {
        //   top: 480px;
        // }
        // @media screen and (max-width: 650px) {
        //   top: 20px;
        // }
        p {
          // position: absolute;
          font-size: 24.1px;
          font-weight: 700;
          color: white;
          text-shadow: 0.01em 0.01em 0 #000000fe, 0.02em 0.02em 0 #00000032,
            0.03em 0.03em 0 #0000002e, 0.04em 0.04em 0 #0000001d,
            0.05em 0.05em 0 #0000000e;
          // left: 32.5%;
          // bottom: -4px;
          z-index: 3;
          transform: rotate(1.5deg);
          @media screen and (max-width: 650px) {
            font-size: 1rem;
          }
        }
        a {
          width: 200px;
          // @media screen and (max-width: 650px) {
          //   width: 150px;
          // }
          @media screen and (max-width: 650px) {
            font-size: 0.8rem;
            width: 100px;
          }
        }
      }
    }
    // }
  }
  .videos-tab {
    position: relative;
    // height: 100%;
    .vid-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // min-height: 700px;
      height: 100%;
      width: 100%;
      .tv-container {
        position: relative;
        top: -30px;
        left: 3%;
        // transform: translateX(-50%);
        // @media screen and (max-width: 950px) {
        //   top: 240px;
        // }
        img {
          height: 700px;
          position: relative;
          z-index: 2;
          @media screen and (max-width: 950px) {
            height: 500px;
          }
          @media screen and (max-width: 650px) {
            height: 400px;
          }
          @media screen and (max-width: 500px) {
            height: 400px;
          }
        }
        @media screen and (max-width: 450px) {
          left: 4.3%;
        }
        @media screen and (max-width: 400px) {
          left: 18.3%;
        }
        .vid-bg-container {
          position: absolute;
          top: 165px;
          left: 84.1px;
          height: 59.9%;
          width: 58.9%;
          outline: 6px solid #000;
          border-radius: 5rem;
          overflow: hidden;
          z-index: 4;
          padding: 0;
          background-color: #000;
          margin: 0;
          scrollbar-width: none;

          @media screen and (max-width: 950px) {
            top: 119px;
            left: 62.1px;
            border-radius: 4rem;
          }
          @media screen and (max-width: 650px) {
            top: 98px;
            left: 51.1px;
            height: 57.9%;
            width: 57.9%;
            border-radius: 2rem;
          }
          @media screen and (max-width: 500px) {
          }
          .warning-txt {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            z-index: 20;
            text-align: center;
            font-size: 1.2rem;
            // background-color: white;
            @media screen and (min-width: 768px) {
              display: none;
            }
            a {
              padding: 5px 30px;
              margin-top: 5px;
              font-size: 1rem;
            }
          }
          video {
            position: relative;
            width: 100%;
            height: 550px;
            top: -51px;
            @media screen and (max-width: 950px) {
              top: -90px;
            }
            @media screen and (max-width: 650px) {
              top: -168px;
            }
          }
          // .vid-contained {
          //   background-color: #1a1a1a;
          //   position: relative;
          //   width: 100%;
          //   scrollbar-width: none;
          //   // z-index: 5;
          //   &::-webkit-scrollbar {
          //     display: none; /* Safari and Chrome */
          //   }
          //   // padding: 0;
          //   // margin: 0;
          //   // top: 0;
          //   // right: -20px;
          //   .vid-container {
          //     /* Just in case there are inline attributes */
          //     width: 100%;
          //     // padding: 5px;
          //     height: auto;
          //     @media screen and (max-width: 768px) {
          //       display: none;
          //     }
          //   }

          //   z-index: 4;

          //   .vid-overlay {
          //     position: absolute;
          //     top: 0;
          //     left: 0;
          //     height: 100%;
          //     width: 100%;
          //     margin: auto;
          //     background-color: #000;
          //     opacity: 0.9;
          //     z-index: 3;
          //     margin: 0;
          //     padding: 0;
          //   }

          //   //   padding: 1rem;
          //   overflow: scroll;
          //   /* Prevent vertical gaps */
          //   line-height: 0;
          //   //   position: relative;
          //   -webkit-column-count: 2;
          //   -moz-column-count: 2;
          //   column-count: 2;
          //   column-gap: 0px;
          //   row-gap: 0px;
          //   width: 100%;
          //   filter: grayscale(0);
          //   cursor: pointer;
          // }
          // .enlarged-vid {
          //   position: fixed;
          //   -khtml-user-select: none;
          //   -o-user-select: none;
          //   -moz-user-select: none;
          //   -webkit-user-select: none;
          //   user-select: none;
          //   top: 54%;
          //   left: 39%;
          //   transform: translate(-50%, -50%);
          //   /* Adjust scale for desired enlargement */
          //   z-index: 1000;
          //   opacity: 1;
          //   height: 55%;
          //   width: auto;
          //   border: 3px solid white;
          //   background-color: #000;
          //   border-radius: 30px;
          //   max-width: 450px;
          //   //   z-index: 3;
          //   //   border-style: solid;
          //   //   border-width: 15px;
          //   //   border-top-color: lighten(#e2e2e2, 50%);
          //   //   border-right-color: lighten(#e2e2e2, 0%);
          //   //   border-bottom-color: lighten(#e2e2e2, 50%);
          //   //   border-left-color: lighten(#e2e2e2, 0%);
          // }
        }
        .vid-meme-floating {
          position: absolute;
          img {
            height: 200px;
          }
          @media screen and (max-width: 1600px) {
            display: none;
          }
          &.img1 {
            right: -300px;
            top: 300px;
          }
          &.img2 {
            left: -300px;
            top: 300px;
          }
        }
      }
    }
  }
  .pooti-vid-link {
    position: relative;
    // top: 650px;
    // left: 10px;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // @media screen and (max-width: 950px) {
    //   top: 590px;
    // }
    // @media screen and (max-width: 950px) {
    //   top: 550px;
    // }
    // @media screen and (max-width: 450px) {
    //   top: 480px;
    // }
    @media screen and (max-width: 650px) {
      top: 20px;
    }
    p {
      // position: absolute;
      font-size: 24.1px;
      font-weight: 700;
      color: white;
      text-shadow: 0.01em 0.01em 0 #000000fe, 0.02em 0.02em 0 #00000032,
        0.03em 0.03em 0 #0000002e, 0.04em 0.04em 0 #0000001d,
        0.05em 0.05em 0 #0000000e;
      // left: 32.5%;
      // bottom: -4px;
      z-index: 3;
      transform: rotate(1.5deg);
      @media screen and (max-width: 650px) {
        font-size: 1rem;
      }
    }
    a {
      width: 200px;
      // @media screen and (max-width: 650px) {
      //   width: 150px;
      // }
      @media screen and (max-width: 650px) {
        font-size: 0.8rem;
        width: 100px;
      }
    }
  }
}
