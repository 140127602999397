.roadmap-bg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f3f3f3;
  position: relative;
  padding-bottom: 6rem;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: 1;
    background-image: url("../../media/bg/bg-grad3.png");
    opacity: 1;
  }
  .roadmap-title {
    .main-roadmap-title {
      letter-spacing: 1px;
      // text-transform: uppercase;
      font-size: 2.5rem;
      font-weight: 700;
      @media screen and (max-width: 400px) {
        font-size: 2rem;
      }
    }
    .main-roadmap-description {
      margin-top: 0.5rem;
      @media screen and (max-width: 400px) {
        width: 80%;
        margin: auto;
        margin-top: 0.5rem;
      }
    }
    // position: relative;
    color: black;
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 2rem;
    margin-top: 3rem;
  }
  .roadmap-content-container {
    width: 750px;
    display: grid;
    grid-template-columns: repeat(3, 250px);
    grid-template-rows: repeat(3, 300px);
    position: relative;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      gap: 4rem;
    }
    .racetrack {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .title {
        color: black;

        // margin-top: 1rem;
        margin-bottom: 10px;
        // font-family: "Pixelify Sans", sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        // text-shadow: 0.01em 0.01em 0 #0000008e, 0.02em 0.02em 0 #0000007e,
        //   0.03em 0.03em 0 #00000062, 0.04em 0.04em 0 #00000054,
        //   0.05em 0.05em 0 #00000033, 0.07em 0.07em 0 #00000057,
        //   0.09em 0.09em 0 #00000027;
        @media screen and (max-width: 768px) {
          font-size: 1.5rem;
        }
      }
      .track {
        position: relative;
        height: 200px;
        width: 200px;
        perspective: 1000px;
        @media screen and (max-width: 768px) {
          height: 300px;
          width: 300px;
        }
        .track-box {
          // overflow: hidden;

          position: relative;
          // z-index: 1;
          height: 100%;
          width: 100%;
          transition: transform 0.6s;
          transform-style: preserve-3d;
          border: 4px solid #000;
          border-radius: 20px;
          box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
            inset 5px 5px rgba(255, 255, 255, 0.5);
          text-decoration: none;
          color: #000;
          border-bottom-width: 7px;
          border-right-width: 6px;
          .track-assets {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-perspective: 0;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            visibility: visible;
            backface-visibility: hidden;
            // -webkit-backface-visibility: hidden;
            // backface-visibility: hidden;
            .track-front {
              &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                // z-index: 1;
                background-image: url("../../media/bg/bg-grad1.png");
                opacity: 1;
              }
              .track-img {
                position: absolute;
                backface-visibility: hidden;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                //   width: 100%;
                z-index: 1;
                @media screen and (max-width: 758px) {
                  height: 300px;
                  width: 300px;
                }
              }
              .lock {
                position: absolute;
                // backface-visibility: hidden;

                top: 43px;
                right: -15px;
                transform: translate(-50%, -50%);
                z-index: 2;
                img {
                  height: 60px;
                }
              }
              .trophy {
                position: absolute;
                bottom: -30px;
                left: 50%;
                transform: translateX(-50%);
                //   transform: translate(-50%, -50%);

                z-index: 2;
                img {
                  height: 60px;
                }
              }
            }
          }
          .track-back {
            // position: absolute;

            transform: rotateY(180deg);
            overflow: hidden;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            -webkit-perspective: 0;
            -webkit-backface-visibility: hidden;
            // -webkit-transform: translate3d(0, 0, 0);
            visibility: visible;
            backface-visibility: hidden;
            // -webkit-backface-visibility: hidden;
            // backface-visibility: hidden;
            // // background-color: #f902fc;
            // border-radius: 13px;
            // // color: #fff;

            ul {
              display: flex;
              justify-content: center;
              // align-items: center;
              flex-direction: column;
              gap: 0.4rem;
              list-style: none;
              padding: 0 1rem;
              @media screen and (max-width: 768px) {
                width: 70%;
                gap: 1rem;
                font-size: 1.2rem;
              }
              li {
              }
            }
            // background-color: blue;
            .unlocked {
            }
            // img {
            //   height: 200px;
            //   @media screen and (min-width: 768px) {
            //     height: 130px;
            //   }
            // }
            // .locked-img {
            //   position: absolute;
            //   bottom: 21.2px;
            //   right: 52.6px;
            //   transform: rotate(90deg);
            //   height: 60px;
            //   @media screen and (min-width: 768px) {
            //     height: 40px;
            //     bottom: -2px;
            //     right: 38px;
            //   }
            // }

            a {
              margin: 0 0 0.8rem 0;
              position: relative;
              z-index: 99;
              width: 50%;
              @media screen and (min-width: 768px) {
                width: 60%;
                font-size: 0.8rem;
                padding: 0.5rem 1rem;
              }
            }
            p {
              width: 80%;
              margin-bottom: 1rem;
              text-align: center;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .track.unlocked:hover .track-box {
        transform: rotateY(180deg);
      }
    }
  }
  .game-container {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    font-size: 1.2rem;
    position: relative;
    z-index: 2;
    a {
      background-color: #f3f3f3;
      transform: rotate(-2.5deg);
      &:hover {
        cursor: pointer;
        // background-color: #f845fb;
        // border: 1px solid #fb00ff;
        transform: scale(1.05);
      }
    }
  }
  .roadmap-img1 {
    position: absolute;
    bottom: 31px;
    right: -400px;
    transform: rotate(-3deg);
    box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
      inset 5px 5px rgba(255, 255, 255, 0.239);
    border: 3px solid #000;
    border-radius: 8px;
    height: 400px;
    border-bottom: 5px solid #000;
    border-right: 5px solid #000;
    video {
      height: 400px;
      border-radius: 5px;
    }
    @media screen and (max-width: 1600px) {
      right: -300px;
    }
    @media screen and (max-width: 1400px) {
      display: none;
    }
  }
  .roadmap-img2 {
    position: absolute;
    top: 100px;
    left: -350px;
    transform: rotate(3deg);

    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    video {
      box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
        inset 5px 5px rgba(255, 255, 255, 0.239);
      border-radius: 10px;
      border: 3px solid #000;
      border-bottom: 5px solid #000;
      border-right: 5px solid #000;
      height: 400px;
      // border-radius: 5px;
    }
    .game-link {
      border: none;
      background-color: transparent;
      box-shadow: none;
      img {
        height: 40px;
        margin-top: 1rem;
      }
    }
    @media screen and (max-width: 1600px) {
      left: -250px;
    }
    @media screen and (max-width: 1400px) {
      display: none;
    }
  }
}
