.box1 {
  grid-area: box1;
  transform: rotate(1deg);
}
.box2 {
  grid-area: box2;
  transform: rotate(2deg);
  @media screen and (max-width: 980px) {
    transform: rotate(-0.5deg);
  }
}
.box3 {
  grid-area: box3;
  transform: rotate(-1deg);
  @media screen and (max-width: 980px) {
    transform: rotate(2deg);
  }
}
.box4 {
  grid-area: box4;
  transform: rotate(3deg);
  @media screen and (max-width: 980px) {
    transform: rotate(-2deg);
  }
}

.jupiter-container {
  // border-top: 5px solid #000;
  width: 100%;
  padding: 4rem 0 4rem 0;
  background-color: #f902fc;
  // background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../media/bg/bg-grad3.png");
    opacity: 1;
  }
  .buy-vid {
    position: absolute;
    overflow: hidden;
    top: 100px;
    right: 10%;
    transform: rotate(-30deg);
    // z-index: 2;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
      inset 5px 5px rgba(255, 255, 255, 0.239);
    border: 3px solid #000;
    border-bottom: 6px solid #000;
    border-right: 6px solid #000;
    border-radius: 9px;
    height: 450px;
    // height: 400px;
    video {
      // border-radius: 7px;
      height: 450px;
    }
  }

  .how-to {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 950px;
    position: relative;
    .title {
      letter-spacing: 1px;
      // text-transform: uppercase;
      font-size: 2.5rem;
      font-weight: 700;
      color: #fff;
      text-shadow: 0 0 3px #000, 0.01em 0.01em 0 #000000fe,
        0.02em 0.02em 0 #000000b0, 0.03em 0.03em 0 #0000008e,
        0.04em 0.04em 0 #00000078, 0.05em 0.05em 0 #00000033,
        0.07em 0.07em 0 #00000057, 0.09em 0.09em 0 #00000027;
      padding-bottom: 3rem;
      padding-top: 2rem;
    }
    .tut-grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // grid-template-rows: repeat(3, 1fr);
      grid-template-areas:
        "box1 ."
        ". box2"
        "box3 ."
        ". box4";
      gap: 1rem;
      &::nth-child(odd) {
        align-items: flex-end;
      }

      @media screen and (max-width: 980px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
          "box1"
          "box2"
          "box3"
          "box4";
      }

      .tut-box {
        width: 400px;
        background-color: #fff;
        border: 2px solid #1a1a1a;
        border-bottom-width: 5px;
        color: #000;
        border-radius: 20px;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 100px 30px 60px;

        box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
          inset 5px 5px rgba(255, 255, 255, 0.5);
        text-align: center;
        @media screen and (max-width: 500px) {
          padding: 80px 15px 50px;
          width: 350px;
        }
        @media screen and (max-width: 400px) {
          padding: 60px 10px 35px;
          width: 300px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          // z-index: 1;
          background-image: url("../../media/bg/bg-grad1.png");
          opacity: 1;
        }
        .box-title {
          font-size: 1.5rem;
          color: #000;
          margin-bottom: 1rem;
        }
        .description {
          color: #000;
          width: 70%;
          margin: auto;
        }
        .number {
          color: #000;
          position: absolute;
          top: 0%;
          left: 50%;
          transform: translate(-50%);
          font-weight: 700;
          font-size: 6rem;
          // color: rgba(26, 26, 26, 0.15);
          color: rgba(249, 2, 252, 0.2);
        }
      }
    }

    .ca {
      margin-top: 3rem;
      color: #fff;
      background-color: #fff;
      border: 2px solid #1a1a1a;
      color: #000;
      border-radius: 10px;
      margin-left: 0;
      margin-right: 0;
      padding: 10px 10px;

      box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
        inset 5px 5px rgba(255, 255, 255, 0.5);
      text-align: center;
      transform: rotate(6deg);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // z-index: 1;
        background-image: url("../../media/bg/bg-grad3.png");
        opacity: 1;
      }
    }

    // .steps-bar {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   width: 950px;
    //   padding-bottom: 1rem;
    //   .step {
    //     display: flex;
    //     align-items: center;
    //     .circle {
    //       width: 40px;
    //       height: 40px;
    //       border-radius: 50%;
    //       background-color: #f902fc;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       color: #fff;
    //       font-weight: bold;
    //       // margin: 0 10px;
    //     }
    //     .line {
    //       width: 230px;
    //       height: 4px;
    //       background-color: #f902fc;
    //     }
    //   }
    // }
    // .instructions {
    //   display: grid;
    //   grid-template-columns: repeat(4, 250px);
    //   gap: 1.1rem;

    //   .step {
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     flex-direction: column;
    //     gap: 0.5rem;
    //   }
    //   .number {
    //     // font-family: pixelify sans;
    //     font-size: 1.3rem;
    //     color: #f902fc;
    //   }
    //   .text {
    //     width: 100%;
    //     line-height: 2;
    //     text-align: center;
    //     color: #000;
    //   }
    // }
    .buy-img1 {
      position: absolute;
      bottom: 241px;
      right: 100px;
      transform: rotate(3deg);
      img {
        height: 250px;
      }
      @media screen and (max-width: 980px) {
        display: none;
      }
    }
    .buy-img2 {
      position: absolute;
      bottom: 499px;
      left: 453px;
      transform: rotate(2deg) translate(-50%);
      img {
        height: 250px;
      }
      @media screen and (max-width: 980px) {
        display: none;
      }
    }
    .buy-img3 {
      position: absolute;
      bottom: 0px;
      left: 10px;
      transform: rotate(2deg) translate(-50%);
      img {
        height: 200px;
      }
      @media screen and (max-width: 980px) {
        display: none;
      }
    }
    .buy-img4 {
      position: absolute;
      top: 0px;
      right: -50px;
      transform: rotate(-2deg);
      img {
        height: 200px;
      }
      @media screen and (max-width: 980px) {
        display: none;
      }
    }
    .listings {
      display: flex;
      padding: 0 0.5rem;
      // justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-top: 2rem;
      .listing {
        img {
          height: 25px;
          border-radius: 50%;
        }
        a {
          padding: 0.5rem;
          border: 1px solid #000;
        }
      }
      .listing1 {
        transform: rotate(3deg);
      }
      .listing2 {
        transform: rotate(-2deg);
      }
    }
  }
  .vid-tutorial {
    // width: 60%;
    // position: absolute;
    // left: 20%;
    // top: 55%;
    // transform: translateX(-50%);
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    video {
      height: 500px;
      border-radius: 1rem;
    }
    .jup-floating-img {
      // position: absolute;
      // right: 20%;
      // top: 55%;
      // transform: translateX(50%);
      img {
        height: 250px;
        // width: 250px;
        // border-radius: 1rem;
      }
    }
  }
  .jup-contact-address {
    text-align: center;
    font-size: 0.8;
    .top-text {
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }
    .ca-jup {
      font-size: 0.8rem;
    }
  }
  // .vid-terminal {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
  .bottom-img {
    img {
      width: 1440px;
    }
  }
}
