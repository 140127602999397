.lore-bg-container {
  background-color: rgba(0, 0, 0, 0.88);
  position: relative;
  color: white;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../media/bg/bg-grad4.png");
    opacity: 0.3;
  }

  .lore-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 7rem;
    padding: 8rem 0 4rem 0;
    @media screen and (max-width: 600px) {
      row-gap: 1rem;
      padding: 6rem 0 4rem 0;
    }
    .title {
      //   margin-top: 6rem;
      font-size: 2.5rem;
      font-weight: 700;
      // font-family: "Pixelify Sans", sans-serif;
      text-shadow: 0 0 3px #000, 0.01em 0.01em 0 #000000fe,
        0.02em 0.02em 0 #000000b0, 0.03em 0.03em 0 #0000008e,
        0.04em 0.04em 0 #00000078, 0.05em 0.05em 0 #00000033,
        0.07em 0.07em 0 #00000057, 0.09em 0.09em 0 #00000027;
      @media screen and (max-width: 1050px) {
        font-size: 2.4rem;
      }
      @media screen and (max-width: 890px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 1.2rem;
      }
    }
    .lore-container {
      background-color: black;
      color: white;
      padding: 80px 100px;
      border: 2px solid black;
      border-radius: 20px;
      height: 400px;
      width: 800px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 4rem;
      @media screen and (max-width: 1400px) {
        width: 500px;
        height: 380px;
        justify-content: center;
        text-align: center;
      }
      @media screen and (max-width: 1050px) {
        padding: 60px 40px;
        width: 400px;
        // height: 520px;
      }
      @media screen and (max-width: 768px) {
        width: 350px;
        height: 470px;
      }
      @media screen and (max-width: 500px) {
        padding: 40px 30px;
        height: 430px;
        width: 250px;
      }
      .floating-lore {
        position: absolute;
      }
      .floating-lore-alone {
        position: absolute;
        left: 372px;
        img {
          height: 351px;
          @media screen and (max-width: 1050px) {
            height: 280px;
          }
          @media screen and (max-width: 600px) {
            height: 150px;
          }
        }
        @media screen and (max-width: 1400px) {
          left: 217px;
        }
        @media screen and (max-width: 1050px) {
          left: 150px;
        }
        @media screen and (max-width: 768px) {
          left: 100px;
        }
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
      .floating-lore.img1 {
        top: -150px;
        left: -75px;
        transform: rotate(-25deg);
        img {
          height: 200px;
          width: 200px;
          @media screen and (max-width: 600px) {
            height: 100px;
            width: 100px;
          }
          @media screen and (max-width: 400px) {
            height: 80px;
            width: 80px;
          }
        }
        @media screen and (max-width: 600px) {
          top: -76px;
          left: -35px;
        }
        @media screen and (max-width: 400px) {
          top: -60px;
          left: -26px;
        }
      }

      .floating-lore.point {
        bottom: -60px;
        img {
          height: 300px;
          @media screen and (max-width: 1050px) {
            height: 250px;
          }
          @media screen and (max-width: 600px) {
            height: 180px;
          }
        }
        @media screen and (max-width: 1050px) {
          bottom: -40px;
        }
      }
      .floating-lore.img2 {
        left: -130px;
        @media screen and (max-width: 1050px) {
          left: -100px;
        }
        @media screen and (max-width: 600px) {
          left: -40px;
        }
      }
      .floating-lore.img3 {
        right: -120px;
        @media screen and (max-width: 1050px) {
          right: -100px;
        }
        @media screen and (max-width: 600px) {
          right: -40px;
        }
      }
      .floating-lore.img4 {
        bottom: -60px;
        left: -220px;
        img {
          height: 500px;
          @media screen and (max-width: 1400px) {
            height: 380px;
          }
          @media screen and (max-width: 1050px) {
            height: 280px;
          }
          @media screen and (max-width: 600px) {
            height: 180px;
          }
        }
        @media screen and (max-width: 1400px) {
          left: -120px;
        }
        @media screen and (max-width: 1050px) {
          left: -120px;
        }
        @media screen and (max-width: 600px) {
          bottom: -20px;
          left: -60px;
        }
        @media screen and (max-width: 400px) {
          bottom: -20px;
          left: -55px;
        }
      }
      .floating-lore-alone.img5 {
        bottom: -390px;
        @media screen and (max-width: 1400px) {
          bottom: -384px;
        }
        @media screen and (max-width: 768px) {
          bottom: -355px;
        }
      }
      .floating-lore-alone.img6 {
        top: -370px;
        height: 280px;
        @media screen and (max-width: 1400px) {
        }
        @media screen and (max-width: 1050px) {
          top: -325px;
        }
        @media screen and (max-width: 768px) {
          top: -336px;
        }
      }
      .floating-lore.img7 {
        top: -217px;
        left: 788px;
        img {
          height: 251px;
          @media screen and (max-width: 1400px) {
            height: 150px;
          }
          @media screen and (max-width: 1050px) {
            height: 120px;
          }
          @media screen and (max-width: 600px) {
            height: 90px;
          }
        }
        @media screen and (max-width: 1400px) {
          top: -132px;
          left: 567px;
        }
        @media screen and (max-width: 1050px) {
          top: -105px;
          left: 377px;
        }
        @media screen and (max-width: 768px) {
          top: -105px;
          left: 277px;
        }
        @media screen and (max-width: 600px) {
          top: -81px;
          left: 277px;
        }
        @media screen and (max-width: 500px) {
          top: -81px;
          left: 220px;
        }
      }

      .lore-txt-container {
        width: 75%;
        // max-width: 500px;
        height: 100%;

        @media screen and (max-width: 1400px) {
          width: 500px;
          display: flex;
          justify-content: center;
          text-align: center;
        }

        .lore-txt {
          height: 100%;
          display: flex;
          flex-direction: column;
          //   justify-content: space-between;
          align-items: flex-start;
          position: relative;
          gap: 10px;

          @media screen and (max-width: 1400px) {
            align-items: center;
          }
          @media screen and (max-width: 500px) {
            gap: 0px;
          }

          .lore-txt-title {
            font-size: 22px;
            font-weight: 700;
            position: relative;
            top: 0;
            margin-bottom: 2rem;
            @media screen and (max-width: 1050px) {
              margin-bottom: 0.8rem;
              font-size: 20px;
              // height: 520px;
            }
            @media screen and (max-width: 500px) {
              font-size: 1.1rem;
            }
          }
          .lore-txt-des {
            font-size: 1rem;
            line-height: 1.4;
            .line {
              padding: 0.5rem 0;
            }
            @media screen and (max-width: 1050px) {
              padding-bottom: 30px 0;
              line-height: 1.3;
              // height: 520px;
            }
            @media screen and (max-width: 500px) {
              font-size: 0.9rem;
            }
          }
          .lore-txt-des.s {
            // margin-top: 2rem;
            width: 90%;
            line-height: 2;
            @media screen and (max-width: 1400px) {
              line-height: 1.8;
            }
            @media screen and (max-width: 1050px) {
              line-height: 1.5;
            }
          }
        }
      }
      .lore-img {
        // flex: 1;
        position: relative;
        height: 100%;
        width: 34%;
        overflow: hidden;
        border-radius: 20px;
        img {
          //   border-radius: 20px;
          height: 100%;
          width: 100%;
        }
        @media screen and (max-width: 1400px) {
          display: none;
        }
      }
      .lore-vid {
        // flex: 1;
        position: relative;
        height: 100%;
        width: 34%;
        overflow: hidden;
        border-radius: 20px;
        video {
          //   border-radius: 20px;
          height: 100%;
          width: 100%;
        }
        @media screen and (max-width: 1400px) {
          display: none;
        }
      }
    }
  }

  .lore-container.lore-box1 {
    margin-top: 8rem;

    transform: rotate(-1.5deg);
    @media screen and (max-width: 768px) {
      height: 430px;
    }
    @media screen and (max-width: 500px) {
      height: 400px;
    }
    @media screen and (max-width: 500px) {
      height: 450px;
    }
  }
  .lore-container.lore-box2 {
    // transform: rotate(1.5deg);
    margin-top: 18rem;

    flex-direction: row-reverse;
    @media screen and (max-width: 1400px) {
      height: 310px;
    }
    @media screen and (max-width: 1050px) {
      height: 330px;
    }
    @media screen and (max-width: 768px) {
      height: 380px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 6rem;
    }
    @media screen and (max-width: 500px) {
      height: 420px;
    }
  }
  .lore-container.lore-box3 {
    margin-top: 18rem;
    transform: rotate(1.5deg);
    @media screen and (max-width: 768px) {
      height: 430px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 6rem;
      margin-bottom: 5rem;
    }
    @media screen and (max-width: 500px) {
      height: 490px;
    }
  }
  .buttons {
    display: flex;
    gap: 1rem;
    a {
      background: white;
      border: 2px solid white;
      color: black;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 1.5rem;
    }
    .btn1 {
      transform: rotate(-4.5deg);
      transition: 0.3s ease;

      &:hover {
        transform: rotate(2.5deg);
      }
    }
    .btn2 {
      transform: rotate(1.5deg);
      transition: 0.3s ease;
      &:hover {
        transform: rotate(-2.5deg);
      }
    }
  }
  position: relative;
}
