@import url("https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

html {
  overflow-x: hidden;
}

.width {
  width: 60%;
  margin: auto;
}

body {
  position: relative;
  font-family: "Lexend", sans-serif;
  overflow-x: hidden;
  // scroll-behavior: smooth;
}

a {
  border: 2px solid rgba(#000000, 0.8);
  background-color: #fff;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.1),
    inset 5px 5px rgba(151, 151, 151, 0.177);
  border-radius: 8px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;
  padding: 0.5rem 2rem;
  text-decoration: none;
  color: #000;
  transition: 0.2s ease;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    font-weight: 700;
  }
}
.link {
  border: 2px solid rgba(#000000, 0.8);
  background-color: #fff;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.1),
    inset 5px 5px rgba(151, 151, 151, 0.177);
  border-radius: 8px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;
  padding: 0.5rem 2rem;
  text-decoration: none;
  color: #000;
  transition: 0.2s ease;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    font-weight: 700;
  }
}

.icon {
  border: 2px solid rgba(#000000, 0.8);
  background-color: #fff;
  box-shadow: 8px 8px 1px -3px rgba(0, 0, 0, 0.5), 3px 3px 0 -1px #0000008b;
  border-radius: 12px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;
}
