.top-page-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  position: relative;
  z-index: 10;
  max-width: 1330px;
  margin: auto;
  // background-color: #f902fc;
  // width: 100%;
  .price-container {
    display: flex;
    justify-content: space-between;
    flex: 1;
    .market-cap {
    }
    .price {
    }
  }
  @media screen and (max-width: 950px) {
    display: none;
  }
  .buy-btn-container {
    flex: 2;
    display: flex;
    justify-content: end;
    .buy-btn {
      // padding: 0.5rem 2rem;
      // border-radius: 1rem;
      // border: 1px solid #f902fc;
      // background-color: #f902fc;
      // color: white;
      text-transform: uppercase;
      // font-size: 1rem;
      // letter-spacing: 3px;
      // font-weight: 700;
      // transition: 0.5s ease;
      transform: rotate(-1.5deg);

      &:hover {
        cursor: pointer;
        // background-color: #f845fb;
        // border: 1px solid #fb00ff;
        transform: scale(1.05);
      }
    }
  }
}
