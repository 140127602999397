.promo-bg-container {
  background-color: rgba($color: #000000, $alpha: 0.88);
  color: #fff;
  position: relative;
  padding-top: 5rem;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../media/bg/bg-grad4.png");
    opacity: 0.3;
    // z-index: 6;
  }

  .promo-content-container {
    width: 60%;
    margin: auto;
    // height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 2rem;
    padding-bottom: 10rem;
    @media screen and (max-width: 1650px) {
      width: 80%;
    }
    @media screen and (max-width: 1250px) {
      width: 90%;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
    }
    @media screen and (max-width: 980px) {
      flex-direction: column;
      height: 900px;
    }
    @media screen and (max-width: 980px) {
      flex-direction: column;
      height: 700px;
    }
    .promo-img-section {
      flex: 1;
      position: relative;
      top: 50px;

      img {
        height: 500px;
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0));
        border-radius: 90px;
      }
    }
    .promo-text-section {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .promo-title {
        font-family: "Pixelify Sans", sans-serif;
        font-size: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6rem 0 2rem 0;
        @media screen and (max-width: 980px) {
          padding: 9rem 0 1rem 0;
          font-size: 2.5rem;
        }
        @media screen and (max-width: 500px) {
          padding: 9rem 0 1rem 0;
          font-size: 2rem;
        }
      }
      .promo-text {
        line-height: 1.6;
        width: 80%;
        max-width: 1200px;
        flex-wrap: wrap;
        margin: 0 auto;
        text-align: center;
        // font-family: "Pixelify Sans", sans-serif;
        font-size: 1rem;
        margin-bottom: 2rem;
        @media screen and (max-width: 980px) {
          font-size: 1.3rem;
          margin-top: 1rem;
        }
        @media screen and (max-width: 500px) {
          font-size: 1rem;
          margin-top: 1rem;
        }
      }
      a {
        cursor: pointer;
        position: relative;
        z-index: 3;
        background-color: #fff;
        color: #000;
        border: 2px solid #fff;
        &:hover {
          transform: rotate(3deg);
          font-weight: 700;
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
  position: relative;
  // z-index: 3;
  .pooti-gang {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5rem;
    .pooti-gang-txt {
      width: 50%;
      text-align: center;
      color: #fff;

      .pooti-gang-title {
        font-size: 2rem;
        width: 60%;
        margin: auto;
        text-align: center;
        margin-bottom: 3rem;
        @media screen and (max-width: 1050px) {
          width: 80%;
        }
        @media screen and (max-width: 768px) {
          font-size: 1.7rem;
          width: 100%;
        }
      }
      .pooti-gang-description {
        font-size: 1.2rem;
        margin-bottom: 8rem;
        .pg {
          margin-bottom: 2.5rem;
        }
      }
      @media screen and (max-width: 1600px) {
        width: 70%;
      }
      @media screen and (max-width: 768px) {
        width: 85%;
      }
    }
    .pooti-gang-img {
      position: relative;
      z-index: 2;
      bottom: -3px;

      // @media screen and (max-width: 1400px) {
      //   padding-bottom: 0;
      // }
      // .outerBevel {
      //   /* of frame */
      //   box-shadow: 4px 6px 12px 0 black;
      //   border-width: 5px;
      //   border-style: solid;
      //   border-color: rgb(109, 84, 58) rgb(24, 19, 13) rgb(24, 19, 13)
      //     rgb(109, 84, 58);
      //   @media screen and (max-width: 1400px) {
      //     border: none;
      //   }
      //   @media screen and (max-width: 1400px) {
      //     box-shadow: none;
      //   }
      // }

      // .flatSurface {
      //   /* of frame */
      //   border: 12px solid rgb(65, 40, 16);
      //   @media screen and (max-width: 1400px) {
      //     border: none;
      //   }
      // }

      // .innerBevel {
      //   /* of frame */
      //   border-width: 5px;
      //   border-style: solid;
      //   border-color: rgb(24, 19, 13) rgb(109, 84, 58) rgb(109, 84, 58)
      //     rgb(24, 19, 13);
      //   @media screen and (max-width: 1400px) {
      //     border: none;
      //   }
      // }
      img {
        // background-color: #f902fc;

        width: 100%;
        max-width: 1925px;
        @media screen and (max-width: 1400px) {
          background-color: transparent;
          width: 100%;
        }
        // @media screen and (max-width: 1350px) {
        //   width: 900px;
        // }
        // @media screen and (max-width: 980px) {
        //   width: 700px;
        // }
        // @media screen and (max-width: 768px) {
        //   width: 400px;
        // }
        // @media screen and (max-width: 480px) {
        //   width: 300px;
        // }
      }
    }
  }
  .line {
    position: relative;
    height: 5px;
    width: 100%;
    background-color: #000;
    z-index: 2;
    border: 1px solid #000;
  }
}
