.marquee2 {
  background-color: #f3f3f3;
  position: relative;
  white-space: nowrap;
  display: inline-block;
  animation: marquee 20s linear infinite;
  font-family: "Pixelify Sans", sans-serif;
  border: 3px solid #000;
  height: 100%;
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../media/bg/bg-grad3.png");
    opacity: 1;
  }
  p {
    display: inline-block;
    color: #000;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0.2rem 0 0.2rem 0;
    letter-spacing: 1px;
    word-spacing: 5px;
  }
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   // z-index: 1;
  //   background-image: url("../../media/bg/bg-grad3.png");
  //   opacity: 1;
  // }
}
