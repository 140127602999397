.data-container {
  display: flex;

  flex-direction: column;
  gap: 0.4rem;
  color: #000;
  @media screen and (max-width: 950px) {
    display: none;
  }
  .label {
    font-size: 0.6rem;
    text-transform: uppercase;
  }
  .value {
    font-size: 1.2rem;
    font-weight: 700;
  }
}
