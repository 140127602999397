.story-container {
  .story-bg-container {
    background-color: #f902fc;
    min-height: 100vh;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      // z-index: 1;
      background-image: url("../../../media/bg/bg-grad1.png");
      opacity: 1;
    }
    .story-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 1rem;
      width: 60%;
      margin: auto;
      @media screen and (max-width: 890px) {
        width: 90%;
        padding: 2rem 0;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
        // padding: 2rem 0;
      }
      .nav-list {
        // position: absolute;
        ul {
          display: flex;
          gap: 1.5rem;
          width: 100%;
          @media screen and (max-width: 890px) {
            width: 90%;
            padding: 0;
            width: 100%;
          }
          .c2 {
            text-decoration: line-through;
            text-decoration-thickness: 2px;
            text-decoration-style: wavy;
            text-decoration-style: solid;
            text-decoration-color: #000;
          }
          li {
            list-style: none;
            transition: 0.2s ease;
            &:nth-child(odd) {
              transform: rotate(1.5deg);
            }
            &:nth-child(even) {
              transform: rotate(-1.5deg);
            }

            &:hover {
              transform: scale(1.1);
              font-weight: 700;
            }
          }
          a {
            @media screen and (max-width: 890px) {
              padding: 0.6rem 1.5rem;
            }
            @media screen and (max-width: 550px) {
              padding: 0.5rem 1rem;
            }
          }
          @media screen and (max-width: 1100px) {
            font-size: 0.8rem;
          }
          @media screen and (max-width: 965px) {
            font-size: 0.6rem;
          }
        }
      }
    }
    .chapter1-container {
      width: 60%;
      max-width: 1330px;
      margin: auto;
      margin-top: 5rem;

      .story-box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8rem;
        background-color: #fff;
        border: 2px solid #1a1a1a;
        border-bottom-width: 5px;
        color: #000;
        border-radius: 20px;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 30px 80px;
        box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
          inset 5px 5px rgba(255, 255, 255, 0.5);
        text-align: center;
        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   bottom: 0;
        //   left: 0;
        //   right: 0;
        //   // z-index: 1;
        //   background-image: url("../../../media/bg/bg-grad5.png");
        //   opacity: 1;
        // }
        .story-text {
          font-size: 1.05rem;
          line-height: 2.5;

          .title {
            font-size: 1.5rem;
            font-weight: 700;
          }
        }
      }
      .story-box.story-reverse {
        flex-direction: row-reverse;
      }

      .story-vid {
        position: relative;
        z-index: 3;
        video {
          height: 500px;
          border-radius: 10px;
          box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
            inset 5px 5px rgba(255, 255, 255, 0.5);
        }
      }
      .chapter1-story {
        padding-bottom: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8rem;
        flex-direction: column;
        .c1-p1 {
          .p1-text {
            font-size: 1.05rem;
            line-height: 2.5;
            .title {
              font-size: 1.5rem;
              font-weight: 700;
            }
          }
          .p1-vid {
          }
        }
        .c1-p2 {
          gap: 3rem;
          padding: 30px 50px;
          flex-direction: row-reverse;
          .p2-text {
            font-size: 1.05rem;
            line-height: 2.5;
            .title {
              font-size: 1.5rem;
              font-weight: 700;
            }
          }
          .p2-vid {
            video {
              height: 500px;
            }
          }
        }
        .c1-p3 {
          gap: 0rem;
          justify-content: center;
          align-items: center;
          // flex-direction: row-reverse;
          .p3-text {
            font-size: 1.05rem;
            line-height: 2.5;
            text-align: center;
            .title {
              font-size: 1.5rem;
              font-weight: 700;
            }
          }
          .p3-vid {
          }
        }
      }
    }
  }
}
