.front-page-bg-container {
  background-color: #f902fc;
  height: 700px;
  position: relative;
  overflow: hidden;
  border-top: 1.8px solid #000;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 700px;

    // z-index: 1;
    background-image: url("../../media/bg/bg-grad4.png");
    opacity: 1;
  }

  .floating-img3 {
    position: absolute;
    bottom: 0px;
    right: 100px;
    transform: rotate(3deg);
    z-index: 2;
    img {
      height: 400px;
    }
    @media screen and (max-width: 1100px) {
      display: none;
    }
  }
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    width: 60%;
    margin: auto;
    @media screen and (max-width: 890px) {
      width: 90%;
      padding: 2rem 0;
    }
    @media screen and (max-width: 400px) {
      width: 100%;
      // padding: 2rem 0;
    }
    .nav-list {
      // position: absolute;
      ul {
        display: flex;
        gap: 1.5rem;
        width: 100%;
        @media screen and (max-width: 890px) {
          width: 90%;
          padding: 0;
          width: 100%;
        }
        li {
          list-style: none;
          transition: 0.2s ease;
          &:nth-child(odd) {
            transform: rotate(1.5deg);
          }
          &:nth-child(even) {
            transform: rotate(-1.5deg);
          }

          &:hover {
            transform: scale(1.1);
            font-weight: 700;
          }
        }
        a {
          @media screen and (max-width: 890px) {
            padding: 0.6rem 1.5rem;
          }
          @media screen and (max-width: 550px) {
            padding: 0.5rem 1rem;
          }
        }
        @media screen and (max-width: 1100px) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 965px) {
          font-size: 0.6rem;
        }
      }
    }
  }

  .floating-img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 60%;
    margin: auto;
    overflow: hidden;
    .floating-img1 {
      position: absolute;
      bottom: 130px;
      right: 0;
      transform: rotate(3deg);
      img {
        height: 200px;
      }
      @media screen and (max-width: 1100px) {
        display: none;
      }
    }
    .floating-img2 {
      position: absolute;
      bottom: 25px;
      left: 0;
      //   transform: translateX(-50%);
      // transform: rotate(183deg);
      img {
        height: 300px;
      }
      @media screen and (max-width: 1100px) {
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        img {
          height: 250px;
        }
      }
    }

    // .floating-img3 {
    //   position: absolute;
    //   top: 50%;
    //   left: -40px;
    //   transform: rotate(90deg) translateX(-150%);
    //   img {
    //     height: 250px;
    //   }
    // }
  }

  .front-page-content-container {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    position: relative;
    z-index: 10;
    .front-page-img {
      flex: 1;
      display: flex;
      justify-content: left;
      align-items: center;
      img {
        height: 300px;
      }
    }
    .front-page-info {
      flex: 1;
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      .title {
        font-family: "Pixelify Sans", sans-serif;
        font-size: 10rem;
        color: white;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
        text-shadow: 0.01em 0.01em 0 #000000fe, 0.02em 0.02em 0 #00000032,
          0.03em 0.03em 0 #0000002e, 0.04em 0.04em 0 #0000001d,
          0.05em 0.05em 0 #0000000e;
        font-weight: 700;
        img {
          height: 150px;
        }
        @media screen and (max-width: 950px) {
          font-size: 7rem;
        }
      }
      .buy-now-btn {
        a {
          // text-transform: uppercase;
          @media screen and (max-width: 950px) {
            font-size: 0.8rem;
          }
        }
      }
      .socials {
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;

        .icon.mobile {
          display: none;
          @media screen and (max-width: 950px) {
            display: flex;
          }
        }
        .icon {
          @media screen and (max-width: 950px) {
            display: none;
          }
        }

        .social {
          transition: 0.2s ease;
          a {
            height: 25px;
            width: 25px;
            color: #000;
            padding: 0.5rem;
            @media screen and (max-width: 950px) {
              padding: 0.3rem;
            }
          }
          &:nth-child(odd) {
            transform: rotate(4deg);
          }
          &:nth-child(even) {
            transform: rotate(-4deg);
          }
          &:hover {
            transform: scale(1.1);
            font-weight: 700;
          }
        }
      }
    }
  }
}

// .scroll-text-container {
//   max-width: 100%;
//   overflow: hidden;
// }

.wrapper {
}

.marquee {
  white-space: nowrap;
  // overflow: hidden;
  display: inline-block;
  animation: marquee 20s linear infinite;
  font-family: "Pixelify Sans", sans-serif;
  border: 3px solid #000;
  height: 100%;
  p {
    //   padding: 0 0.5rem;
    display: inline-block;
    color: #000;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0.2rem 0 0.2rem 0;
    letter-spacing: 1px;
    word-spacing: 5px;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

// .scroll-text-container {
//   //   border: 3px solid black;
//   //   border-radius: 5px;
//   overflow: hidden;
// }

// .scroll-text {
//   padding: 0.2rem 0;
//   color: #f902fc;
//   font-weight: 700;
//   font-size: 1.2rem;
//   white-space: nowrap;
//   /* animation properties */
//   -moz-transform: translateX(100%);
//   -webkit-transform: translateX(100%);
//   transform: translateX(100%);

//   -moz-animation: my-animation 10s linear infinite;
//   -webkit-animation: my-animation 10s linear infinite;
//   animation: my-animation 10s linear infinite;
//   span {
//     padding: 0 1rem;
//   }
// }

// /* for Firefox */
// @-moz-keyframes my-animation {
//   from {
//     -moz-transform: translateX(100%);
//   }
//   to {
//     -moz-transform: translateX(-100%);
//   }
// }

// /* for Chrome */
// @-webkit-keyframes my-animation {
//   from {
//     -webkit-transform: translateX(100%);
//   }
//   to {
//     -webkit-transform: translateX(-100%);
//   }
// }

// @keyframes my-animation {
//   from {
//     -moz-transform: translateX(100%);
//     -webkit-transform: translateX(100%);
//     transform: translateX(100%);
//   }
//   to {
//     -moz-transform: translateX(-100%);
//     -webkit-transform: translateX(-100%);
//     transform: translateX(-100%);
//   }
// }
