// background-color: rgb(243, 243, 243);
.com-bg-container {
  position: relative;
  background-color: rgba($color: #f3f3f3, $alpha: 0.88);
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../media/bg/bg-grad3.png");
    opacity: 1;
  }
  .com-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    gap: 2rem;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      text-align: center;
    }
    .com-txt-container {
      width: 600px;
      .com-title {
        font-size: 2rem;
        font-family: "Pixelify Sans", sans-serif;
        color: #f902fc;
        margin-bottom: 2.5rem;
        @media screen and (max-width: 768px) {
          font-size: 1.7rem;
          margin-bottom: 2rem;
        }
        @media screen and (max-width: 500px) {
          font-size: 1.4rem;
          margin-bottom: 1rem;
        }
      }
      .com-txt {
        @media screen and (max-width: 768px) {
          width: 80%;
          margin: auto;
          font-size: 0.9rem;
        }
        @media screen and (max-width: 768px) {
          width: 60%;
          margin: auto;
        }
      }
      .com-links {
        display: flex;
        padding: 0 0.5rem;
        // justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-top: 2rem;
        @media screen and (max-width: 1200px) {
          justify-content: center;
        }
        .social {
          transition: 0.2s ease;
          a {
            height: 25px;
            width: 25px;
            color: #000;
            padding: 0.5rem;
          }
          &:nth-child(odd) {
            transform: rotate(4deg);
          }
          &:nth-child(even) {
            transform: rotate(-4deg);
          }
          &:hover {
            transform: scale(1.1);
            font-weight: 700;
          }
        }
      }
    }
    .com-img-container {
      position: relative;
      z-index: 2;
      img {
        height: 600px;
        @media screen and (max-width: 1200px) {
          height: 300px;
        }
      }
    }
  }
}
