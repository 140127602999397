.disc-container {
  position: relative;
  background-color: #f902fc;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../media/bg/bg-grad3.png");
    opacity: 1;
  }

  .ca {
    margin-top: 3rem;
    color: #fff;
    background-color: #f3f3f3;
    border: 2px solid #1a1a1a;
    color: #000;
    border-radius: 10px;
    margin-left: 0;
    margin-right: 0;
    padding: 10px 10px;
    width: 500px;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.08),
      inset 5px 5px rgba(255, 255, 255, 0.5);
    text-align: center;
    transform: rotate(-4deg);
    .ca-img {
      position: absolute;
      left: -159px;
      top: -128px;
      img {
        height: 200px;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
      width: 400px;
    }
    @media screen and (max-width: 500px) {
      font-size: 0.6rem;
      width: 300px;
    }
  }
  .listings {
    display: flex;
    padding: 0 0.5rem;
    // justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    .listing {
      img {
        height: 25px;
        border-radius: 50%;
      }
      a {
        padding: 0.5rem;
        border: 1px solid #000;
      }
    }
    .listing1 {
      transform: rotate(3deg);
    }
    .listing2 {
      transform: rotate(-2deg);
    }
  }
  .disc-content-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      // margin-top: 2rem;
      font-family: "Pixelify Sans", sans-serif;
      font-size: 8rem;
      color: white;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: black;
      text-shadow: 0.01em 0.01em 0 #000000fe, 0.02em 0.02em 0 #00000032,
        0.03em 0.03em 0 #0000002e, 0.04em 0.04em 0 #0000001d,
        0.05em 0.05em 0 #0000000e;
      font-weight: 700;
      img {
        height: 150px;
      }
      @media screen and (max-width: 768px) {
        font-size: 6rem;
      }
      @media screen and (max-width: 500px) {
        font-size: 5rem;
      }
    }
    .disc-disc {
      margin-top: 4rem;
      font-size: 1rem;
      width: 60%;
      color: #fff;
      text-align: center;
      margin-bottom: 4rem;
      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
      }
      @media screen and (max-width: 500px) {
        font-size: 0.6rem;
      }
      .disc-copyright {
        margin-top: 1rem;
      }
    }
  }
}
@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
